@import 'variables';
body{
  .icon-file{
    float: right;
  }
  .MuiDialog-paper{
    .data-upload-modal{
      .data-upload-instructions{
        margin-bottom: 15px;
        .info-area{
          padding: 10px;
          border-radius: 3px;
          color: #856404;
          background-color: #fff3cd;
          border-color: #ffeeba;
        }
        .alert-msg{
          @include elementStyles($size: 13px, $fontFamily: $ibmplexMedium, $color: #000000);
        }
      }
      .dropzone-area{
        width: 500px;
        position: relative;
        margin: 0 auto;
        .drag-drop-cnt{
          .MuiDropzoneArea-textContainer{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .MuiDropzoneArea-text{
              margin-top: auto;
            }
            .MuiDropzoneArea-icon{
              color: #3f51b5;
            }
          }
        }
      }
    }
    .issue-files-modal{
      h4{
        margin-bottom: 8px;
      }
      ul{
        li{
          font-size: 14px;
          padding: 5px 0px;
          border-bottom: 1px solid #d7d7d7;
          word-wrap: break-word;
          word-break: break-all;
        }
      }
    }
    .file-upload-note{
      font-size: 14px;
      margin-top: 3px;
    }
  }
  .application-swicther-modal{
		.MuiDialog-paperWidthSm {
			max-width: 100%;
		}
	}
  .application-landing-page{
    .MuiTabs-scroller{
      margin-bottom: 0px;
    }
    h3{
        @include elementStyles($size: $font16, $color:#5666f9, $fontFamily: $ibmplexMedium, $weight:500, $margin:0 0 6px 0);
    }
    .application-card{
        padding: 20px;
        background: #7d7561;
        box-shadow: 0 2px 2px rgba(0,0,0,.25);
        border-radius: 4px;
        color: #fff;
        // min-height: 225px;
        position: relative;
        &.no-access-app{
            opacity: 0.85;
        }
        cursor: pointer;
        .lock-icon{
            position: absolute;
            top: 10px;
            right: 20px;
            z-index: 10;
        }
        .app-title{
            margin-bottom: 15px;
            img{
                width: 50px;
                margin-right: 15px;
            }    
            h4{
                @include elementStyles($size: $font18, $fontFamily: $ibmplexMedium, $weight:500);
                sup{
                    color: #ab0202;
                }
            }
        }
        .app-desc{
            p{
                @include elementStyles($size: $font16, $fontFamily: $ibmplexRegular, $weight:400);
            }
        }
        button{
            position: absolute;
            bottom: 10px;
            left: 20px;
            z-index: 20;
            color: #fff;
            font-size: medium
        };
        .no-access-content{
            position: absolute;
            bottom: 10px;
            left: 20px;
            z-index: 20;
            color: #fff03b;
            @include elementStyles($size: $font13, $fontFamily: $ibmplexRegular, $weight:400);
        }
    }   
}
  .file-upload-stats{
    position: fixed;
    width: 380px;
    bottom: 0;
    right: 0;
    z-index: 999;
    background: #ffffff;
    box-shadow: 0 2px 2px rgba(0,0,0,0.4);
    h4{
      padding: 10px 20px;
      border-bottom: 1px solid #000000;
      position: relative;
      ul{
        position: absolute;
        right: 15px;
        cursor: pointer;
      }
    }
    .file-list{
      padding: 0px 20px 40px 20px;
      overflow: auto;
      ul.list-unstyled{
        .file-item{
          padding: 10px 0;
          border-bottom: 1px solid #d7d7d7;
          position: relative;
          h5{
            @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $color: #000000);
            word-wrap: break-word;
            word-break: break-all;
            padding-right: 40px;
          }
          p{
            @include elementStyles($size: 11px, $fontFamily: $ibmplexRegular, $color: #000000);
            word-wrap: break-word;
            word-break: break-all;
            padding-right: 40px;
          }
          .file-status{
            position: absolute;
            right: 8px;
            top: 30%;
          }
        }
      }
    }
  }
   .records-comment-list{
     .MuiTableCell-head {
      padding: 5px 10px;
      background: #b5bedb;
      font-size: 13px;
      font-weight: 600;
      font-family: 'Conv_IBMPlexSans-semibold';;
    }
    .MuiTableCell-body {
      padding: 5px 10px;
    }
    .MuiTableCell-body td:nth-child(2n) {
      background: #e6ebfb;
    }
  }
  .data-room-section{
    .empty-folder{
      clear: both;
      height: 440px;
      border: 1px dashed #000000;
      position: relative;
      text-align: center;
      background: rgba(113,99,186,.15);
      border-radius: 4px;
      .upload-now-content{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        p{
          margin-bottom: 10px;
        }
      }
    }
    .ocr-file-status, .file-count-stats{
      margin-bottom: 20px;
      &.ocr-file-status{
        background: rgba(206, 235, 225, .30);
        padding:15px;
        box-shadow: 0 2px 2px rgba(0, 0, 0,.25);
      }
    }
    .file-count-card{
      background: #ffffff;
      box-shadow: 0 2px 2px rgba(0,0,0,.25);
      padding: 10px 10px 10px 20px;
      border-radius: 4px;
      position: relative;
      width: 100%;
      p{
        @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: #000000);
        margin-bottom: 3px;
      }
      h3{
        @include elementStyles($size: 24px, $color:#3f51b5, $fontFamily: $ibmplexMedium);

      }
      .vertical-bar{
        position: absolute;
        height: 100%;
        width: 4px;
        background: #5476EF;
        left: 0;
        top: 0;
      }
    }
    .data-room-head{
      padding: 8px 10px;
      background: #e6eaea;
      position: fixed;
      top: 51px;
      left: 239px;
      right: 0;
      z-index: 100;
      .asset-switcher{
        li{
          &:first-child{
            width: 200px !important;
            border-right: 1px solid #d7d7d7;
            padding-right: 8px;
          }
          margin-right: 15px;
          img{
            margin-right: 8px;
            width: 18px;
          }
          &.link-export{
            a{
              @include elementStyles($size: 13px, $color:#3f51b5, $fontFamily: $ibmplexMedium);
            }
          }
        }
      }
      .more-action-cta{
        float: right;
        >li{
          margin-left: 15px;
        }
      }
    }
    .data-room-layout{
      margin-bottom: 5px;
      nav{
        ol{
          li{
            a{
              font-size: 15px;
              cursor: pointer;
              color: #bd10e0;
            }
            p{
              font-size: 15px;
            }
          }
        }
      }
      .data-room-layout-options{
        float: right;
        li{
          margin-left: 15px;
          .data-rm-switcher{
            background: #E3E3E3;
            display: inline-block;
            border-radius: 4px;
            cursor: pointer;
            span{
              padding: 5px 10px 3px 10px;
              display: inline-block;
              &.active{
                background: #E8EEFF;
              }
            }
          }
        }
      }
    }
    .data-room-nav{
      margin-bottom: 15px;
      .data-room-upload-option{
        // float: right;
        li{
          margin-left: 6px;
          &:first-child{
            margin-left: 0;
          }
        }
      }
      .data-room-bulk-ops{
        float: right;
        li{
          margin-left: 10px;
          &:first-child{
            margin-left: 0;
          }
        }
      }
    }
    .dm-card-layout{
      clear: both;
      ul.dm-card-layout-row{
        width: 100%;
        display: block !important;
        li.dm-card-layout-blk{
          margin-right: 20px;
          margin-bottom: 20px;
          .folder-card{
            background: #ffffff;
            box-shadow: 0 2px 2px rgba(0,0,0,.25);
            width: 170px;
            border-radius: 4px;
            position: relative;
            .folder-icon{
              height: 120px;
              background: #F0F0F0;
              position: relative;
              border-top-right-radius: 4px;
              border-top-left-radius: 4px;
              background-position: center;
              background-repeat: no-repeat;
              .data-other-info{
                padding: 2px;
                position: absolute;
                bottom: 0px;
                left: 0;
                right: 0;
                ul{
                  li{
                    border-right: 1px solid #d7d7d7;
                    margin-right: 2px;
                    padding-right: 2px;
                    &:last-child{
                      border-right: none;
                      margin-right: 0;
                      padding-right: 0;
                    }
                  }
                }
              }
              .MuiChip-sizeSmall{
                height: 18px;
              }
              .file-status{
                position: absolute;
                right: 4px;
                top: 4px;
                font-size: 10px;
                padding: 2px 4px !important;
              }
              .check-box{
                position: absolute;
                top: 0px;
                left: 0px;
                z-index: 99;
              }
              .folder-img{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
              }
            }
            .folder-info{
              padding: 10px;
              background: #ffffff;
              border-bottom-right-radius: 4px;
              border-bottom-left-radius: 4px;
              position: relative;
              h5{
                @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $color: #000000);
                margin-bottom: 3px;
                cursor: pointer;
                &:hover{
                  text-decoration: underline;
                }
              }
              p{
                @include elementStyles($size: 11px, $fontFamily: $ibmplexRegular, $color: rgba(0,0,0,.70));
              }
              .shared-info{
                display: inline-block;
                margin-left: 5px;
                position: absolute;
                right: 15px;
                top: 10px;
                cursor: pointer;
                img{
                  width: 15px;
                }
              }
            }
            .context-menu-action{
              position: absolute;
              right: 10px;
              bottom: 0px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .dm-list-layout{
      table{
        thead th{
          padding: 10px;
        }
        tbody td{
          padding: 10px;
        }
      }
      .more-info-list{
        ul{
          li{
            border-right: 1px solid #d7d7d7;
            margin-right: 2px;
            padding-right: 2px;
            &:last-child{
              border-right: none;
              margin-right: 0;
              padding-right: 0;
            }
          }
        }
      }
    }
    .dm-folder-tree{
      // width: 300px;
      .rc-tree{
        overflow-x: auto;
        padding-left: 3px;;
        .rc-tree-list{
          width: 300px;
          .rc-tree-list-holder{
            .rc-tree-list-holder-inner{
              .rc-tree-treenode{
                .rc-tree-node-content-wrapper{
                  max-width: 300px;
                  &.rc-tree-node-selected{
                    background-color: transparent;
                    box-shadow: none;
                    opacity: 1;
                    color: #bd10e0;
                    font-weight: bold;
                  }
                  .rc-tree-title{
                    @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular);
                  }
                }
              }
            }
          }
        }
      }
    }
    .dataroom-search{
      margin-bottom: 5px;
      ul{
        li{
          position: relative;
          input{
            width: 400px;
            padding: 8px 8px 8px 24px;
            border: none;
            border-radius: 4px;
            box-shadow: 0 2px 2px rgb(0, 0, 0, 0.25);
            &:focus, &:active{
              outline: none;
            }
          }
          .search-icon{
            position: absolute;
            left: 2px;
            top: 4px;
            opacity: 0.6;
            width: 20px;
          }
          button{
            margin-left: 5px;
            position: relative;
            top: 1px;
          }
        }
      }
    }
  }
  .data-share-modal{
    padding: 0 0 15px 0;
    .invite-collabration{
      border-top: 1px solid #d7d7d7;
      padding-top: 20px;
      margin-top: 20px;
      .data-collabration-blk{
        padding: 15px;
        background: #e8e8e8;
        .shared-ppl-lst{
          margin-bottom:10px;
          padding: 5px;
          background-color: #fff;
        }
        h4{
          @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: rgba(0,0,0,.70), $margin: 0 0 6px 0);
        }
        p{
          cursor: pointer;
          padding: 8px 10px;
          border: 1px solid #a5a4a4;
          border-radius: 4px;
          background: rgba(255,255,255,.4);
          @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $color: rgba(0,0,0,.3));
        }
      }
    }
    .public-link-card{
      h4{
        @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $margin: 0 0 6px 0);
      }
      p{
        @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $margin: 10px 0 0 0);
      }
      .link-advance-setting{
        float: right;
        cursor: pointer;
        @include elementStyles($size: 13px, $fontFamily: $ibmplexMedium, $color:#3f51b5);
      }
      .share-advance-setting{
        margin-top: 6px;
        p{
          margin-top: 0;
        }
        .MuiTypography-root{
          font-size: 13px;
        }
        .MuiOutlinedInput-root{
          height: 30px;
          width: 100px;
        }
      }
    }
  }
  .move-data-modal{
    .folder-list{
      li{
        display: block;
        width: 100%;
        background: #f1f1f1;
        clear: both;
        cursor: pointer;

        @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $margin: 0 0 3px 0, $padding:5px);
        &.active{
          background: #d0d0d0;
        }
      }
    }
    .no-folder{
      text-align: center;
      @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $margin: 30px 0);
    }
  }
  .global-search-section{
    .heading{
      h2{
        @include elementStyles($size: 18px, $fontFamily: $ibmplexRegular, $color:#bd10e0, $margin: 0 0 10px 0);
      }
    }
    .search-params{
      background: #F1F1F1;
      height: 560px;
      border-radius: 4px;
      box-shadow: 0 2px 2px rgba(0,0,0,.2);
      padding: 15px;
      p{
        font-size: 15px;
        color: #856404;
        margin-bottom: 20px;
      }
      ul{
        li{
          margin-bottom: 20px;
          label{
            color: rgba(0, 0, 0, 0.54);
          }
        }
      }
    }
    .search-list{
      padding: 10px 40px 10px 10px;
      box-shadow: 0 1px 1px rgba(0,0,0,.2);
      margin-bottom: 1px;
      position: relative;
      .search-action-list{
        position: absolute;
        right: 20px;
        top: 10px;
        li{
          margin-left: 8px;
          cursor: pointer;
        }
      }

      .content-icon{
        margin-right: 15px;
        width: 30px;
      }
      &:nth-child(even){
        background: #ebeff6;
      }
      &:nth-child(odd){
        background: #f5f7fa;
      }
      .search-content{
        h4{
          @include elementStyles($size: 15px, $fontFamily: $ibmplexRegular, $color:#000000, $margin: 0 0 4px 0);
          cursor: pointer;
        }
        p{
          @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $color:rgba(0,0,0,.9), $margin: 0 0 8px 0);

        }
        ul{
          li{
            margin-right: 10px;
            padding-right: 10px;
            border-right: 1px solid #d7d7d7;
            @include elementStyles($size: 13px, $fontFamily: $ibmplexMedium, $color:rgba(0,0,0));
            &:last-child{
              border-right: none;
            }
            span{
              color: rgba(0,0,0,.6);
            }
          }
        }
      }
    }
  }
  .minipack-cover-pic-modal{
    .cover-pic-action{
      ul{
        margin-bottom: 15px;
        li{
          margin-right: 10px;
          button{
            a{
              &:hover{
                text-decoration: none;
              }
            }
          }
          label{
            cursor: pointer;
            border-radius: 4px;
            padding: 7px 10px;
            color: #3f51b5;
            border: 1px solid rgba(63, 81, 181, 0.5);
            &:hover{
              background-color: rgba(63, 81, 181, 0.04);
              border: 1px solid #3f51b5;
            }
          }
        }
      }
    }
  }
  .data-room-properties{
    .folder-properties{
      margin-top: 80px;
      table{
        margin: 0 auto;
        margin-top: 20px;
        tr{
          td{
            padding: 4px;
            @include elementStyles($size: 15px, $fontFamily: $ibmplexMedium, $color: rgba(0,0,0,1));
            &:first-child{
              @include elementStyles($size: 15px, $fontFamily: $ibmplexRegular, $color: rgba(0,0,0.7));
            }
          }
        }
      }
    }
  }
  .prs-bulk-ops{
    background: #e1e1e7;
    padding:8px 15px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 10;
    box-sizing: content-box;
    ul{
      li{
        margin-right: 6px;
        cursor: pointer;
        .blk-ops-menu{
          background: #fff;
          padding:5px 10px;
          border-radius: 20px;
          font-size: $font13;
          &.delete-menu{
            display: inline-block;
            padding:5px 10px;
          }
        }
      }
    }
  }
  .prs-details-section{
    position: relative;
    .prs-detail-content{
      // margin-left: 230px;
      overflow: auto;
      .issue-title, .issue-description, .issue-other-information{
        margin-bottom: 15px;
        &.issue-title{
         .user-info{
          margin-top: 5px;
          li{
            &::after{
              content: ',';
            }
            &:first-child, &:last-child{
              &::after{
                content: none !important;
              }
              
            }
            margin-right: 5px;
            .span-label{
              font-size: 13px;
              font-family: $ibmplexMedium;
              font-weight: 500;
            }
          }
         } 
        }
      }
    }
    .prs-comments-blk{
      .comment{
        padding: 5px 10px;
        border-bottom: 1px solid #d7d7d7;
        clear: both;
        &:last-child{
          border-bottom: none;
        }
        .user-detail{
          margin-bottom: 10px;
          .user-name{
            margin-left: 6px;
            h4{
              @include elementStyles($size: $font16, $color: #000000, $fontFamily: $ibmplexMedium, $weight:500);
            }
            ul{
              li{
                margin-right: 5px;
                padding-right: 5px;
                border-right: 1px solid #d7d7d7;
                cursor: pointer;
                span{
                  @include elementStyles($size: $font13, $color: #000000, $fontFamily: $ibmplexRegular, $weight:400);
                }
                &:last-child{
                  border-right: none;
                }
              }
            }
          }
        }
      }
      .add-comment{
        clear: both;
        .comment-box{
          margin-bottom: 10px;
        }
        ul{
          float: right;
          margin: 15px 0px 10px 0px;
          li{
            margin-left: 10px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px){
  body{
    .data-room-section{
      .data-room-head{
        position: unset;
        top: auto;
        left: auto;
        right: auto;
        .asset-switcher{
          li{
            &:first-child{
              width: 100%!important;
              border-right: none;
              padding-right: 0px;
              margin-right: auto;
            }
            a{
              font-size: 13px;
              img{
                width: 15px;
                margin-right: 5px;
              }
            }
            .MuiAutocomplete-inputRoot{
              .MuiAutocomplete-input{
                font-size: 13px;
              }
            }
          }
        }
        .more-action-cta{
          li{
            &:first-child{
              margin-left: auto;
            }
            &.drivetool-menu, &.more-action-menu{
              div{
                z-index: 99;
                .MuiPaper-root{
                  .MuiList-root{
                    .MuiMenuItem-root{
                      font-size: 12px;
                      line-height: 16px;
                      min-height: 25px;
                      padding: 6px 8px;
                      svg{
                        padding-left: 3px;
                        font-size: 15px;
                      }
                    }
                  }
                }
              }
              button{
                width: 125px;
                .MuiButton-label{
                  font-size: 12px;
                  line-height: 15px;
                }
              }
            }
            &.more-action-menu{
              div{
                z-index: 99;
              }
            }
          }
        }
      }
      .data-room-layout{
        margin-bottom: 15px;
        h4{
          font-size: 15px;
          color: #bd10e0;
          margin-top: 15px;
        }
        .data-room-layout-options{
          li{
            font-size: 14px;
            img{
              width: 20px;
              position: relative;
              top: 2px;
            }
            .data-rm-switcher{
              svg{
                font-size: 20px;
              }
            }
          }
        }
      }
      .data-room-nav{
        .data-room-upload-option{
          display: inline-block;
          li{
            margin-right: 10px;
            margin-left: auto;
            &:last-child{
              margin-top: 10px;
              button{
                width: 128px;
              }
            }
            button{
              white-space: nowrap;
              font-size: 12px;
            }
          }
        }
      }
      .dm-card-layout{
        ul{
          &.dm-card-layout-row{
            li{
              &.dm-card-layout-blk{
                .folder-card{
                  width: 145px;
                  .folder-icon{
                    height: 100px;
                  }
                }
              }
            }
          }
        }
      }
      .data-room-graphic{
        .file-count-stats, .ocr-file-status{
          .file-count-card{
            width: 100%;
            padding: 5px 5px 5px 15px;
            p{
              font-size: 14px;
            }
          }
        }
      }
    }
    .MuiDialog-paper{
      .MuiDialogContent-root{
        .dropzone-area{
          width: auto;
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px) and (min-width: 768px){
  body{
    .data-room-section{
      .data-room-head{
        position: unset;
        top: auto;
        left: auto;
        right: auto;
        margin-bottom: 15px;
      }
    }
  }
}
//landscape
@media only screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
  body{
    .data-room-section{
      .data-room-head{
        position: unset;
        top: auto;
        left: auto;
        right: auto;
        margin-bottom: 15px;
      }
    }
  }
}
