@import 'variables';
body {
  .data-room-file-checklists{
    .checklist-status{
      display: inline-block;
      padding: 3px 0px;
      border-radius: 20px;
      text-align: center;
      width: 110px;
      @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular);
      >span{
        justify-content: center;
        cursor: pointer;
      }
      &.active{
        background: rgb(100, 235, 100);
      }
      &.completed{
        background: rgb(255, 74, 74);
      }
    }
    ul.list-inline{
      li{
        margin-left: 5px;
      }
    }
    .checklists-hd-cta{
      h2{
        @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: #000, $margin:0 0 2px 0);
      }
      margin-bottom: 10px;
    }
    .document-sections{
      margin-bottom: 15px;
      .mui-table-format{
        .doc-description{
          @include elementStyles($size: 11px, $fontFamily: $ibmplexRegular, $color: #000);
          font-style: italic;
          margin-top: 4px;
        }
      }
      .checklist-subsections{
        padding: 10px;
      }
      .doc-sec-head{
        padding: 5px 10px;
        h4{
          @include elementStyles($size: 15px, $fontFamily: $ibmplexRegular, $color: #0b00ff);
        }
        p{
          @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $color: #000);
        }
        ul{
          float: right;
        }
      }
    }
  }
  .data-templates-section {
    .data-template-head{
      margin-bottom: 20px;
      .heading {
        margin-bottom: 5px;
        h3{
          @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: #bd10e0);
        }
      }
      .add-template-cta{
        float:right;
      }
    }
    .dropzone-area{
      .warning-msg{
        border: 1px solid;
      }
    }
  }
  .dropzone-area{
    .warning-msg{
      @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $color: #a78701);
      background: #fff1b6;
      border: 1px solid #a78701;
      border-radius: 4px;
      padding: 3px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}
.checklist-file-linking{
  h4{
      svg{
          margin-right: 4px;
      }
      span{
          @include elementStyles($size: $font13, $color: #000, $fontFamily: $ibmplexRegular, $weight:400);
          cursor: pointer;
      }
  }
  .selected-files{
      background: #f0f0f0;
      h3{
          @include elementStyles($size: $font14, $color: #000, $fontFamily: $ibmplexRegular, $weight:400);
          padding: 8px 10px;
//                 border-bottom: 1px solid #d7d7d7;
      }
      .selected-files-blk{
          padding: 8px 10px;
          .file-list{
              display: flex;
              padding: 4px;
              border-bottom: 1px solid #d7d7d7;
              p{
                  @include elementStyles($size: 9px, $color: #000, $fontFamily: $ibmplexRegular, $weight:400);
              }
          }
          span.remove-file{
              margin-left: auto;
          }
      }
  }
  .file-location{
      @include elementStyles($size: 9px, $color: #000, $fontFamily: $ibmplexRegular, $weight:400);
      margin-left: 24px;
  }
  .PrivateSwitchBase-root-1{
      padding: 0 !important;
      float: right;
  }
  .linking-navigation{
      background: #f1f1f1;
      padding: 4px;
      margin-bottom: 10px;
  }
}
