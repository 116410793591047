@import 'variables';
body{
  .records-home-section{
    .home-filter{
      ul{
        li{
          margin-right: 10px;
          &.dd-width{
            width: 200px;
          }
        }
      }
      .total-size{
        padding: 10px;
        float: right;
        @include elementStyles($size: 16px, $fontFamily: $ibmplexSemiBold, $color: #000000);
        &.size-box-shadow{
          padding: 10px;
          box-shadow: 0 2px 2px rgba(0,0,0,.25);
          border-radius: 4px;
       }
      }
    }
  }
  .asset-listing-section{
    .heading{
      // margin-bottom: 15px;
      clear: both;
      h3{
        @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: #bd10e0);
        margin-bottom: 4px;
      }
      p{
        @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $color: #000000);
      }
      .add-asset-cta{
        // float: right;
      }
    }
    .asset-bulk-ops{
      margin-bottom: 5px;
      overflow: hidden;
      float: right;
      ul{
        // float: right;
        li{
          margin-left: 15px;

        }
      }
    }
    table{
      clear: both;
      tbody{
        .asset-info{
          .folder-icon{
            width: 30px;
          }
          .asset{
            margin-left: 10px;
          }
        }
        ul{
          li{
            margin-left: 10px;
            &:first-child{
              margin-left: 0 !important;
            }
          }
        }
      }
    }
    .asset-tab{
      clear: both;
      .MuiTab-root{
        min-width: 115px !important;
        .MuiTab-wrapper{
          font-size: 14px !important;
        }
      }
      .PrivateTabIndicator-root-2{
        width: 115px !important;
      }
      .MuiTabs-scrollable {
        margin-bottom: 4px !important
      }
    }
  }
  .fleets-management{
    .list-inline{
      .list-inline-item{
        margin-left: 5px;
        &:first-child{
          margin-left: 0;
        }
      }
    }
    .MuiTab-root{
      min-width: 130px !important;
      padding: 0 !important;
      .MuiTab-wrapper{
        font-size: 13px;
      }
    }
    .MuiTabs-root{
      .MuiTabs-flexContainer{
        width: 100px;
      }
    }
    .serial-no-search{
      position: relative;
      .search-icon{
        position: absolute;
        top: 1px;
        right: 1px;
        z-index: 9;
        background: #fff;
        height: 96%;
        text-align: center;
        align-items: center;
        justify-content: center;
        display: flex;
        cursor: pointer;
        padding: 0 10px;
        svg{
          font-size: 30px;
          justify-content: center;
        }
      }
    }
    
  }
}
@media only screen and (max-width: 600px){
  body{
    .inventory .records-dataroom-modal .MuiOutlinedInput-input{
      font-size: 13px;
    }
    .records-home-section{
      .home-filter{
        ul{
          li{
            font-size: 14px;
            &.dd-width{
              width: 150px;
              .MuiInputBase-input{
                font-size: 14px!important;
              }
            }
          }
        }
        .total-size{
          font-size: 11px;
        }
      }
    }
    .asset-listing-section{
      .asset-tab{
        .MuiTab-root{
          font-size: 13px;
        }
      }
      .asset-bulk-ops{
        margin-bottom: 15px;
        ul{
          li{
            margin-left: 8px;
            &:first-child{
              margin-left: auto;
            }
            .MuiButton-text{
              padding-right: 0px;
            }
          }
        }
      }
      table {
        thead{
          tr{
            white-space: nowrap;
            th{
              padding: 10px;
            }
          }
        }
        tbody{
          tr{
            td{
              padding: 10px;
              ul{
                li{
                  img{
                    width: 18px;
                  }
                  svg{
                    font-size: 22px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 601px) and (max-width: 767.98px) {
  body {
    .asset-listing-section{
      .asset-tab{
        .MuiTab-root{
          font-size: 13px;
        }
      }
      .asset-bulk-ops{
        margin-bottom: 15px;
        ul{
          li{margin-left: 8px;
            &:first-child{
              margin-left: auto;
            }
            .MuiButton-text{
              padding-right: 0px;
            }
          }
        }
      }
      table {
        thead{
          tr{
            white-space: nowrap;
            th{
              padding: 10px;
            }
          }
        }
        tbody{
          tr{
            white-space: nowrap;
            td{
              padding: 10px;
              ul{
                li{
                  img{
                    width: 18px;
                  }
                  svg{
                    font-size: 22px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px) and (min-width: 768px){
  body{
    .asset-listing-section{
      .asset-tab{
        .MuiTab-root{
          font-size: 13px;
        }
      }
      .asset-bulk-ops{
        margin-bottom: 15px;
        ul{
          li{margin-left: 8px;
            &:first-child{
              margin-left: auto;
            }
            .MuiButton-text{
              padding-right: 0px;
            }
          }
        }
      }
      table {
        thead{
          tr{
            white-space: nowrap;
          }
        }
        tbody{
          tr{
            white-space: nowrap;
            td{
              ul{
                li{
                  img{
                    width: 18px;
                  }
                  svg{
                    font-size: 22px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
